.header {
  position: relative;
  z-index: 10;

  @include mobile {
    height: 73px;
  }

  .container {
    padding: 13px 10px;
    max-width: calc(100% - 30px);
    border-radius: 0 0 5px 5px;
    border: 1px solid $headerGrey2;
    border-top: 0;
    background: linear-gradient(to bottom, $headerGrey1, $headerGrey2);

    @include non-desktop {
      padding: 10px;
    }

    @include tablet {
      height: 83px;
    }

    @include mobile {
      height: 73px;
      width: calc(100% - 30px);
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .products-new & {
    height: 100px;

    @include mobile {
      height: 73px;
    }

    .container {
      width: calc(100% - 30px);
      max-width: 964px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      position: fixed;
      transition: width 0.25s ease-in-out, max-width 0.25s ease-in-out, left 0.25s ease-in-out, transform 0.25s ease-in-out;

      @include mobile {
        width: 100%;
      }
    }

    .header-container {
      width: 100%;
      max-width: 940px;
      margin: 0 auto;
    }
  }

  .products-new.is-scrolled & {
    .container {
      width: 100%;
      max-width: none;
      border-radius: 0;
      padding: 10px;

      @include non-desktop {
        height: 73px;
      }
    }

    .header__navigation {
      width: calc(100% - 160px);

      @include mobile {
        top: 73px;
        width: 100%;
      }
    }

    .header__link {
      width: 140px;
    }
  }
}

.header__link {
  display: inline-block;
  vertical-align: middle;
  width: 220px;
  transition: width 0.25s ease-in-out;

  @include non-desktop {
    width: 165px;
  }

  @include mobile {
    width: 140px;
  }
}

.header__navigation {
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 240px);
  margin-left: 15px;
  transition: width 0.25s ease-in-out;

  @include desktop {
    padding-right: 20px;
    padding-top: 15px;
  }

  @include tablet {
    width: calc(100% - 170px);
    margin: 0;
  }

  @include mobile {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 0;
    padding: 15px 15px 10px;
    top: 73px;
    border-radius: 0 0 5px 5px;
    background: $white;
    box-shadow: 0 4px 5px rgba($color: #000000, $alpha: 0.15);
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

    &.is-active {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }

  &>.navigation__item {
    display: inline-block;
    margin-left: 10px;
    position: relative;

    &.has-submenu:hover {
      .navigation__submenu {
        display: block;
      }
    }

    @include tablet {
      margin: 0;
    }

    @include mobile {
      display: block;
      margin: 0;
      text-align: center;
      width: 100%;
    }

    span.navigation__link {
      cursor: auto;
      pointer-events: none;
    }

    &>.navigation__link {
      display: inline-block;
      font-size: 0.9375rem;
      padding: 8px 10px;
      transition: opacity 0.35s ease-in-out;
      text-transform: uppercase;
      font-family: $secondaryFont;
      text-transform: uppercase;
      border-radius: 8px;
      line-height: 1;

      @include mobile {
        display: block;
        font-size: 1rem;
      }

      @include tablet {
        font-size: 0.75rem;
        padding: 8px;
      }

      &.is-active {
        background: $darkGrey;
        box-shadow: inset 0 0 10px #000;
        color: $grey;
      }
    }

    &>a.navigation__link:hover {
      background: $darkGrey;
      box-shadow: inset 0 0 10px #000;
      color: $grey;
    }
  }

  .navigation__submenu {
    position: absolute;
    left: 0;
    width: 155px;
    background: $white;
    border: 1px dotted $headerGrey2;
    text-align: left;
    z-index: 2;
    display: none;

    @include mobile {
      position: static;
      width: 100%;
      text-align: right;
      border: 0;
      margin-bottom: 5px;
      display: block;
    }

    .navigation__item {
      border-bottom: 1px dotted $headerGrey2;

      &:last-child {
        border-bottom: 0;

        @include mobile {
          border-bottom: 1px dotted $headerGrey2;
        }
      }
    }

    .navigation__link {
      padding: 6px 8px;
      font-size: 0.875em;
      display: block;

      @include mobile {
        font-size: 1em;
        text-align: center;
      }

      &:hover {
        background: $green;
      }
    }
  }
}

.header__mobile {
  position: absolute;
  width: 28px;
  height: 20px;
  background: transparent;
  right: 15px;
  top: 27px;
  display: none;

  &:focus {
    outline: 0;
  }

  .line {
    width: 100%;
    height: 2px;
    background: $darkerGrey;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transform-origin: center center;
  }

  .line--mid {
    top: 8px;
  }

  .line--bot {
    top: 16px;
  }

  &.is-active {
    .line--top {
      transform: translateY(8px) rotateZ(45deg);
    }

    .line--mid {
      opacity: 0;
    }

    .line--bot {
      transform: translateY(-8px) rotateZ(-45deg);
    }
  }

  @include mobile {
    display: block;
  }
}