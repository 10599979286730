.body--facility {
  .content {
    .title {
      font-family: $titleFont;
      color: $lightOrange;
      text-transform: none;
      font-size: 1.25em;
    }
  }
}

.facility__group {
  width: 230px;
  display: inline-block;
  vertical-align: top;

  @include mobile {
    width: 100%;
  }

  &+.facility__group {
    border-left: 1px dashed $grey2;
    margin-left: 30px;
    padding-left: 20px;
    width: 250px;

    @include mobile {
      border-left: 0;
      margin-left: 0;
      padding-left: 0;
      width: 100%;
    }
  }
}

.facility__title {
  @include gradientOrange;
  font-family: $titleFont;
  font-size: 1.125rem;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.facility__list {
  list-style: none;
  margin-bottom: 5px;
  border-top: 1px solid $grey2;
  padding-top: 10px;
}

.facility__item {
  font-size: 0.75rem;
  line-height: 2;

  .content & a {
    color: $black !important;
    display: inline-block;
    vertical-align: middle;
    font-family: $defaultFont;

    &:hover {
      color: $green !important;
    }
  }

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background: url('../images/bullet.png') no-repeat center center;
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }
}

.facility-gallery {
  padding: 10px;
  background: $white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 560px;
  max-width: 100%;
  margin: 0 auto;
  text-align: left;

  @include mobile {
    padding-bottom: 40px;
  }
}

.facility-gallery__text {
  color: $darkerGreen;
  font-weight: bold;
}

.facility-gallery__nav {
  position: absolute;
  right: 10px;
  bottom: 0px;
  background: $white;
  z-index: 2;
}

.facility-gallery__button {
  display: inline-block;
  border: 0;
  padding: 0;
  cursor: pointer;
  z-index: 2;
  outline: 0;

  img {
    display: block;
  }
}