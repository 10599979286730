@import 'normalize';
@import '_variables';
@import '_mixins';
@import 'components/header';
@import 'components/footer';
@import 'components/banner';
@import 'components/shortcut';
@import 'components/breadcrumb';
@import 'components/facility';
@import 'components/gallery';
@import 'components/scrollingfacts';
@import 'components/sidebar';
@import 'components/specification';
@import 'components/newproduct';
@import 'components/copyright';

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

body {
  background: $grey;
  line-height: 1.4;
  position: relative;
  font-size: 0.875em;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 220px;
    background: $green;
  }
}

body,
input,
button {
  font-family: $mainFont;
}

button {
  border: 0;
  font-weight: 600;
  cursor: pointer;
  outline: 0;
}

img {
  max-width: 100%;
}

.body__container {
  background: #fff;
  box-shadow: 0px -10px 20px rgba(10, 31, 68, 0.2), 0px 0px 1px rgba(10, 31, 68, 0.3), 0px 5px 5px rgba(109, 109, 109, 0.1);
  border-radius: 0 0 8px 8px;
}

.body__container,
.container {
  max-width: 960px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.body__container {
  width: calc(100% - 40px);

  @include mobile {
    width: 100%;
  }
}

.container {
  padding: 0 20px;
}

.container--watermark {
  background: url('../images/logo_watermark.jpg') 18% 72% no-repeat;

  @include mobile {
    background-image: none;
  }

  .body--products-biodiesel-info & {
    background-position: 18% 48%;
  }

  .body--products-biodiesel-en & {
    background-position: 18% 80%;
  }
}

a {
  color: inherit;
}

header a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;

  &.style--disc {
      list-style: disc;
      padding-left: 30px;
  }
}

main {
  padding: 20px 0;
}

hr {
  border-color: $grey;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $titleFont;
  text-transform: uppercase;
  line-height: 1.2;
}

.no-mobile {
  @include mobile {
    display: none !important;
  }
}

.only-mobile {
  display: none;

  @include mobile {
    display: block !important;
  }
}

.align-center {
  text-align: center;
}

.row {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

[class|="column"] {
  float: left;
  padding: 0 10px;
}

.column-3 {
  width: calc(100% / 4);
}

.column-4 {
  width: calc(100% / 3);
}

.column-5 {
  width: calc(100% / 12 * 5);
}

.column-6 {
  width: calc(100% / 2);
}

.column-7 {
  width: calc(100% / 12 * 7);
}

.column-8 {
  width: calc(100% / 12 * 8);
}

.column-9 {
  width: calc(100% / 12 * 9);
}

.t--inherit {
  color: inherit !important;
}

.t--green {
  color: $green !important;
}

.t--bold {
  font-weight: bold !important;
}

.t--nobold {
  font-weight: normal !important;
}

.t--underline {
  text-decoration: underline !important;
}

.t--nounderline {
  text-decoration: none !important;
}

.t--justify {
  text-align: justify !important;
}

.t--bigger {
  font-size: 1rem;
}

.table-wrapper {
  overflow: auto;
  font-size: 12px;
  text-align: left;
}

body:not(.products-new) .content {
  min-height: 165px;
  text-align: justify;
  font-size: 0.8125rem;

  .body--home & {
    font-size: 1rem;
  }

  a {
    text-decoration: none;
    color: $green;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }

  &+.content {
    margin-top: 20px;
  }
}

body:not(.products-new) .content--grey {
  background: $lightGrey;
  padding: 20px;
  border-radius: $borderRadius;
  border: 1px solid $grey;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.vh,
.visuallyhidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.align--center {
  text-align: center;
}

.title-bullet {
  line-height: 20px;
  margin: 0 0 10px;
  text-transform: none;
  font-size: 1em;
  position: relative;
  padding-left: 30px;
  text-align: left;

  @include mobile {
    font-size: 1rem;
  }

  p+& {
    margin-top: 35px;
  }

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url('../images/bullet_logo.gif');
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    left: 0;
  }
}

.title-with-line {
  font-size: 1.875rem;
  font-weight: 800;
  text-transform: none;
  position: relative;
  margin: 10px 0 25px;

  @include mobile {
    font-size: 1.625rem;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: 50px;
    left: 0;
    bottom: -10px;
    background: $lighterOrange;
  }

  p+& {
    margin-top: 50px;

    @include mobile {
      margin-top: 30px;
    }
  }
}

.list--logo {
  list-style: none;

  li {
    position: relative;
    padding-left: 24px;
    margin: 5px 0;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      background-image: url('../images/bullet_logo.gif');
      background-repeat: no-repeat;
      background-size: cover;
      display: inline-block;
      position: absolute;
      left: 0;
    }
  }
}

.contact-type {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-0 {
  margin: 0;
}

.p-t-20 {
  padding-top: 20px;
}

hr.dashed {
  border-style: dashed;
}

.btn {
  padding: 8px 20px;
  border: 0;
  border-radius: 30px;
  text-decoration: none;
  color: $white;
  font-weight: 500;
  display: inline-block;
  line-height: 1;

  &--grey {
    background: $darkerGrey2;
  }
}

@include mobile {
  [class|="column"] {
    width: 100% !important;
  }

  .title {
    font-size: 3.25em;
    max-width: 100%;
  }

  .content__title {
    font-size: 2em;
  }

  .divider-image {
    height: 300px;
  }

  h3 {
    font-size: 2em;
  }
}
