.shortcut {
  &>.container {
    padding: 0 5px;
  }

  .column-4 {
    @include tablet {
      width: 100%;
    }
  }
}

.shortcut__item {
  margin: 20px 30px 20px 0;
  background: linear-gradient(to bottom, $headerGrey2, $headerGrey1);
  border-radius: 10px;
  border: 1px solid $headerGrey2;
  position: relative;
  padding: 15px 20px 20px 20px;
  text-align: left;

  @include desktop {
    min-height: 210px;
  }

  @include tablet {
    padding-bottom: 30px;
  }

  @include mobile {
    margin: 20px 0;
    padding: 15px 15px 30px;
  }
}

.shortcut__title {
  font-size: 1.125rem;
  font-family: $secondaryFont;
  font-weight: bold;  
  margin: 0;
  margin-bottom: 5px;
  @include gradientGreyText;

  @include mobile {
    font-size: 1.25rem;
  }
}

.shortcut__text {
  font-size: 0.75rem;
  margin: 0;
  padding-right: 80px;

  @include mobile {
    font-size: 1rem;
    padding-right: 0;
  }
}

.shortcut__image {
  width: 130px;
  display: block;
  position: absolute;
  right: -40px;
  top: 50%;
  transform: translateY(-50%);

  @include mobile {
    width: 130px;
    top: auto;
    right: 0;
    transform: none;
    margin: -2px -30px 0px 15px;
    position: relative;
    float: right;
  }
}

.shortcut__link {
  display: inline-block;
  padding: 5px 30px;
  text-transform: uppercase;
  color: $white;
  background: linear-gradient(to bottom, $lightGreen, $darkGreen);
  border: 1px solid $darkGreen;
  font-size: 0.75em;
  border-radius: 20px;
  position: absolute;
  bottom: -15px;
  right: 85px;
  font-weight: 500;
  letter-spacing: 1px;
  text-shadow: -1px 1px 1px $darkGreen;
  text-decoration: none;

  @include tablet {
    right: 105px;
  }

  @include mobile {
    right: auto;
    left: 15px;
    font-size: 0.875em;
  }
}