.copyright {
  background: #fff;
  &::before {
    display: none;
  }

  .container {
    max-width: 740px;
  }

  h1 {
    font-size: 1rem;
    font-family: $secondaryFont;
    font-weight: bold;  
    margin: 1.5em 0 0.5em;
    @include gradientGreyText;
    text-transform: none;
  }
}