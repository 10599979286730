.sidebar {
  text-align: center;

  @include mobile {
    margin-bottom: 20px;
  }
}

.sidebar__image {
  border: 1px solid $black;
  border-radius: $borderRadius;
  box-shadow: 2px 2px 4px rgba(100, 100, 100, 0.6);
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
  width: 100%;
}

.sidebar__content {
  background: linear-gradient(180deg, $lighterOrange, $orange);
  background-size: 100%;
  border-radius: 0 0 $borderRadius $borderRadius;
  padding: 20px 10px 70px;
  max-width: 85%;
  margin: 0 auto;
  text-align: left;
  position: relative;

  @include mobile {
    background: transparent;
    padding: 0;
    margin: 0;
    max-width: 100%;
  }

  &>* {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: '';
    display: block;
    width: 83px;
    height: 95px;
    background: url('../images/side_bg_logo.png') center center no-repeat;
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
    opacity: 0.8;

    @include mobile {
      background: none;
    }
  }
}

.sidebar__mobile {
  position: absolute;
  width: 28px;
  height: 20px;
  background: transparent;
  left: 15px;
  top: 10px;
  display: none;
  z-index: 2;

  &:focus {
    outline: 0;
  }

  .line {
    width: 100%;
    height: 2px;
    background: $darkText;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transform-origin: center center;
  }

  .line--mid {
    top: 8px;
  }

  .line--bot {
    top: 16px;
  }

  &.is-active {
    .line--top {
      transform: translateY(8px) rotateZ(45deg);
    }

    .line--mid {
      opacity: 0;
    }

    .line--bot {
      transform: translateY(-8px) rotateZ(-45deg);
    }
  }

  @include mobile {
    display: block;
  }
}

.sidebar__title {
  margin: 0;
  text-transform: none;
  font-size: 0.8125rem;
  font-family: $mainFont;
  color: #fff;
  position: relative;
  padding-bottom: 8px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  @include mobile {
    position: static;
    padding: 11px 0 11px 55px;
    min-height: 38px;
    color: $darkText;

    &::after {
      display: none;
    }
  }
}

.sidebar__list {
  &:last-child {
    .sidebar__item:last-child {
      border-bottom: 0;
    }
  }

  &+.sidebar__title {
    margin-top: 20px;
  }

  @include nonMobile() {
    display: block !important;
  }

  @include mobile {
    display: none;
    background: $titleShadow;
    width: 100%;
  }
}

.sidebar__item {
  padding: 5px 0 5px 25px;
  font-size: 0.8125rem;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @include mobile {
    font-size: 1em;
  }

  &:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  a {
    color: $black;
    text-decoration: none;

    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background: url('../images/bullet.png') no-repeat center center;
    display: inline-block;
    left: 3px;
    top: 5px;
    position: absolute;
  }

  @include mobile {
    display: block;
    padding: 10px 0 10px 55px;
    margin: 0;
    text-align: left;
    border-top: 1px solid $grey2;

    &::before {
      left: 23px;
      top: 11px;
    }

    a:hover {
      color: $black
    }
  }
}

.sidebar__item--extra-line {
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.sidebar__wrapper {
  &+.sidebar__wrapper {
    margin-top: 20px;

    @include mobile {
      margin-top: 0;
    }
  }

  @include mobile {
    border-top: 2px solid $white;
    background: $lighterOrange;
    position: relative;
  }
}