@mixin gradientOrange() {
  background: $orange;
  background: -webkit-linear-gradient($lighterOrange, $lightOrange 40%, $darkOrange);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    color: $orange;
    background: transparent;
  }
}

@mixin gradientGreyText() {
  background: $black;
  background: -webkit-linear-gradient(#7b7b7b, $black 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media all and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
    color: $black;
    background: transparent;
  }
}

@mixin smallMobile() {
  @media screen and (max-width: 330px) {
    @content;
  }
}

@mixin mobile() {
  @media screen and (max-width: 700px) {
    @content;
  }
}

@mixin nonMobile() {
  @media screen and (min-width: 700px) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: 601px) and (max-width: 1023px) {
    @content;
  }
}

@mixin non-desktop() {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin desktop() {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin bigDesktop() {
  @media screen and (min-width: 1600px) {
    @content;
  }
}