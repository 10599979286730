$white: #fff;
$black: #000;

$mainFont: Arial,
Helvetica,
sans-serif;
$secondaryFont: 'Roboto Condensed',
Arial,
Helvetica,
sans-serif;
$titleFont: 'Roboto',
Arial,
Helvetica,
sans-serif;
$defaultFont: Verdana,
Arial,
Helvetica,
sans-serif;
$newFont: 'Barlow',
Arial,
Helvetica,
sans-serif;
$newFontCondensed: 'Barlow Condensed',
Arial,
Helvetica,
sans-serif;

$borderRadius: 18px;

$lightGreen: #BDD07F;
$green: #8AB357;
$darkGreen: #426F0A;
$darkerGreen: #006600;
$tintOrange2: #f1e5d8;
$tintOrange: #ffeed1;
$lighterOrange: #ffc326;
$lightOrange: #F90;
$orange: #f36326;
$darkOrange: #ff0101;
$lightGrey: #f3f3f3;
$grey: #E8E8E8;
$grey2: #c6c6c6;
$darkGrey: #7D7D7D;
$darkerGrey: #555;
$darkerGrey2: #4d565b;

$stickyText: #808080;
$titleShadow: #f2f2f2;
$darkText: #31404a;

$headerGrey1: #FBFBFB;
$headerGrey2: #CBCBCB;