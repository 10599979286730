.banner__divider {
  height: 25px;
  background: linear-gradient(to bottom, $grey, $headerGrey2);
  border-radius: 5px;
  margin: 10px 0 20px;
}

.banner--plain {
  direction: rtl;
  margin-bottom: 20px;

  .banner__image,
  .banner__content {
    display: inline-block;
    vertical-align: top;
    border-top: 1px solid $grey;
    border-bottom: 1px solid $grey;
  }

  .banner__image {
    width: 70%;

    @include mobile {
      width: 100%;
      border-bottom: 0;
      border-top: 1px solid $grey;
    }
  }

  .banner__image--wide {
    width: calc(100% - 205px);

    @include mobile {
      width: 100%;
    }

    &+.banner__content {
      width: 200px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .banner__content {
    width: calc(30% - 5px);
    padding: 20px 0;
    position: relative;
    direction: ltr;

    @include mobile {
      width: 100%;
      border-top: 0;
      border-bottom: 1px solid $grey;
      height: auto !important;
    }
  }

  .banner__title {
    margin: 0;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    font-family: $secondaryFont;
    @include gradientGreyText;
  }

  .banner__caption {
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 10px;
    text-align: right;
    width: 100%;
    font-family: $secondaryFont;
    font-style: italic;
    font-size: 1rem;
    padding-right: 10px;
    color: $darkText;

    @include mobile {
      top: 5px;
      bottom: auto;
    }
  }
}