.products-new {
  font-family: $newFont;
  color: $darkText;
  background: $white;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button,
  input {
    font-family: $newFont;
  }

  .body__container {
    width: 100%;
    max-width: none;
    box-shadow: none;
  }

  .header {
    max-width: 964px;
    margin: 0 auto;
  }

  .footer {
    text-align: center;
    padding: 0 0 20px;
    font-size: 1em;
  }

  main {
    @include mobile {
      padding-top: 0;
    }
  }
}

.sticky-nav {
  background: $grey;
  width: 100%;
  top: 121px;
  left: 0;
  padding: 3px 0 7px;
  z-index: 9;
  transition: top 0.25s ease-in-out;
  position: fixed;

  &+.cushion {
    padding-top: 40px;
  }

  @include mobile {
    top: 73px;
  }

  .products-new.is-scrolled & {
    top: 73px;
  }
}

.sticky-nav__mobile {
  position: absolute;
  width: 28px;
  height: 20px;
  background: transparent;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  &:focus {
    outline: 0;
  }

  .line {
    width: 100%;
    height: 2px;
    background: $green;
    top: 0;
    left: 0;
    position: absolute;
    display: block;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
    transform-origin: center center;
  }

  .line--mid {
    top: 8px;
  }

  .line--bot {
    top: 16px;
  }

  &.is-active {
    .line--top {
      transform: translateY(8px) rotateZ(45deg);
    }

    .line--mid {
      opacity: 0;
    }

    .line--bot {
      transform: translateY(-8px) rotateZ(-45deg);
    }
  }

  @include mobile {
    display: block;
  }
}

.sticky-nav__active {
  padding: 10px 10px 10px 38px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  color: $stickyText;
}

.sticky-nav__list {
  list-style: none;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    display: block;
    position: absolute;
    top: 45px;
    background: $titleShadow;
    left: 0;
    width: 100%;
    opacity: 0;
    transform: translateY(-10px);
    visibility: hidden;
    transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;

    &.is-active {
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    }
  }
}

.sticky-nav__item {
  display: inline-block;
  padding: 0;
  margin: 0 10px;

  @include tablet {
    margin: 0;
    font-size: 0.875rem;
  }

  @include mobile {
    display: block;
    padding: 5px 0 5px 55px;
    margin: 0;
    text-align: left;
    border-top: 1px solid $grey2;
  }
}

.sticky-nav__link {
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  padding: 5px;
  color: $stickyText;

  .body--products-glycerine .sticky-nav__item:first-child & {
    @include mobile {
      min-width: 140px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 50px;
    background: $lighterOrange;
    left: 50%;
    bottom: 0;
    color: $stickyText;
    transform: translateX(-50%) scaleY(0);
    transform-origin: center center;
    transition: transform 0.25s ease-in-out, color 0.25s ease-in-out;

    @include mobile {
      display: none;
    }
  }

  &:hover,
  &.is-active {
    color: $darkText;

    @include mobile {
      color: $green;
    }

    &::after {
      transform: translateX(-50%) scaleY(1);
    }

  }
}

// Surfactants Banner

.s-banner {
  min-height: calc(100vh - 250px);
  padding: 60px 0 70px;
  background: url('../images/product-surfactant-banner.jpg') left center no-repeat;
  background-size: cover;
  overflow: hidden;

  @include non-desktop {
    min-height: auto;
    padding: 50px 0 40px;
  }

  @include tablet {
    background-position: 80% center;
  }

  @include mobile {
    background-position: 23% center;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: 0.6;
    }
  }

  .container {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.s-banner__content {
  width: 50%;

  @include tablet {
    width: 65%;
  }

  @include mobile {
    width: 100%;
  }
}

.s-banner__title {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2.5rem;
  position: relative;
  margin: 0 0 25px;
  line-height: 1.1;

  &::before {
    content: 'Surfactants';
    display: block;
    position: absolute;
    text-transform: uppercase;
    font-size: 4.5rem;
    left: -30px;
    bottom: -10px;
    color: #b5b8bb;
    opacity: 0.2;
    z-index: -1;

    @include tablet {
      left: 0;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: calc(100% + 80px);
    left: -40px;
    bottom: -10px;
    background: linear-gradient(90deg, rgba(255, 196, 38, 0.1), $lighterOrange 10%, $lighterOrange 90%g, rgba(255, 196, 38, 0.1));
  }
}

// Glycerine Banner
.g-banner {
  min-height: calc(100vh - 250px);
  padding: 100px 0 70px;
  background: #ebeff2 url('../images/product-glycerine-banner.jpg') right top no-repeat;
  background-size: cover;
  overflow: hidden;

  @include non-desktop {
    min-height: auto;
    padding: 50px 0 40px;
  }

  @include tablet {
    background-position: 30% center;
  }

  @include mobile {
    background-position: 25% top;
    background-size: auto 410px;
  }

  @include smallMobile {
    background-position: 37% top;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.g-banner__content {
  width: 50%;

  @include tablet {
    width: 65%;
  }

  @include mobile {
    width: 100%;
  }
}

.g-banner__title {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 2.5rem;
  position: relative;
  margin: 0 0 95px;
  line-height: 1.1;

  @include mobile {
    margin-bottom: 110px;
    font-size: 2.125rem;
  }

  span {
    display: block;

    &::before {
      content: '';
      display: block;
      color: #b5b8bb;
      position: absolute;
      text-transform: uppercase;
      font-size: 5.875rem;
      opacity: 0.2;
      z-index: -1;
      line-height: 1;

      @include mobile {
        font-size: 4rem;
      }
    }
  }

  .top {
    position: relative;

    &::before {
      content: 'REFINED';
      left: -30px;
      bottom: 0;

      @include non-desktop {
        left: 0;
      }
    }
  }

  .bottom {
    position: absolute;
    top: 100%;
    left: 50px;

    @include mobile {
      top: 110%;
      left: 0;
      line-height: 1;
    }

    &::before {
      content: 'GLYCERINE';
      left: 0;
      top: 0;

      @include tablet {
        top: 0;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: calc(100% + 80px);
    left: -40px;
    bottom: -4px;
    background: linear-gradient(90deg, rgba(255, 196, 38, 0.1), $lighterOrange 10%, $lighterOrange 90%g, rgba(255, 196, 38, 0.1));
  }
}

// Bubble

.bubble__list {
  max-width: 430px;
  position: relative;
  margin-top: 30px;

  @include tablet {
    max-width: 390px;
  }

  @include mobile {
    width: 340px;
    max-width: 340px;
    text-align: center;
  }

  @include smallMobile {
    width: 320px;
    max-width: 320px;
    margin-left: -10px;
  }
}

.bubble__image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120%;
  max-width: none;
  z-index: -1;
}

.bubble__item {
  width: 200px;
  height: 200px;
  vertical-align: top;
  border-radius: 50%;
  padding: 20px;
  background: $lighterOrange;
  position: relative;
  z-index: 2;
  color: $darkerGrey2;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  line-height: 1em;
  box-shadow: 0 4px 3px rgba($color: #000000, $alpha: 0.15);

  @include tablet {
    width: 180px;
    height: 180px;
    padding: 15px;
  }

  @include mobile {
    width: 160px;
    height: 160px;
    padding: 13px;
    margin-bottom: 10px;
  }

  @include smallMobile {
    width: 140px;
    height: 140px;
    padding: 10px;
  }

  &.has-icon {
    padding-top: 40px;

    @include non-desktop {
      padding-top: 35px;
    }

    &::before {
      top: 60px;

      @include non-desktop {
        top: 45px;
      }

      @include smallMobile {
        top: 42px;
      }
    }

    &::after {
      bottom: 30px;

      @include non-desktop {
        bottom: 20px;
      }

      @include smallMobile {
        bottom: 15px;
      }
    }
  }

  &:first-child {
    margin-right: 20px;

    @include mobile {
      margin-right: 12px;
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    width: 50px;
    height: 2px;
    background: $white;
    left: 50%;
    transform: translateX(-50%);

    @include smallMobile {
      width: 35px;
    }
  }

  &::before {
    top: 50px;

    @include non-desktop {
      top: 35px;
    }

    @include smallMobile {
      top: 32px;
    }
  }

  &::after {
    bottom: 50px;

    @include non-desktop {
      bottom: 35px;
    }

    @include smallMobile {
      bottom: 30px;
    }
  }

  &>* {
    display: block;
    margin: 0 auto;
  }

  .icon {
    position: absolute;
    top: 15px;

    @include non-desktop {
      top: 10px;
      max-height: 25px;
    }
  }

  .number,
  .text {
    font-family: $newFontCondensed;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1em;
  }

  .number {
    font-size: 3.5rem;

    @include mobile {
      font-size: 2.5rem;
    }
  }

  .text {
    font-size: 2rem;

    @include mobile {
      font-size: 1.5rem;
    }
  }

  .smaller {
    font-size: 0.75em;
  }

  .subtitle {
    font-size: 13px;
    font-weight: 500;
    margin: 2px 0;

    @include mobile {
      font-size: 11px;
    }

    @include smallMobile {
      font-size: 10px;
    }

    &--big {
      font-size: 1.125rem;

      @include non-desktop {
        font-size: 1em;
      }
    }
  }

  .percentage {
    font-size: 0.75em;
  }

  &.bubble__item--quantity {
    .text {
      text-transform: none;
      font-size: 2.375rem;
      margin: 0;

      @include mobile {
        font-size: 2rem;
      }

      @include smallMobile {
        font-size: 1.75rem;
      }
    }

    .subtitle {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.1;

      @include smallMobile {
        font-size: 0.875rem;
      }
    }
  }
}

// TEXT IMAGE

.s-textimage {
  padding: 60px 0;
  overflow: hidden;

  @include non-desktop {
    padding: 60px 0 40px;
  }

  &.is-flipped {
    direction: rtl;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    &>.s-textimage__title {
      margin-bottom: 40px;
    }
  }

  #cpab & {
    padding-top: 100px;

    @include non-desktop {
      padding-top: 40px;
    }
  }
}

.s-textimage__content,
.s-textimage__image {
  display: inline-block;
  width: calc(50% - 10px);
  vertical-align: top;
  direction: ltr;

  @include mobile {
    width: 100%;
  }
}

.s-textimage__content {
  @include tablet {
    width: calc(60% - 10px);
  }

  @include mobile {
    margin-top: 20px;
  }
}

.s-textimage__image {
  @include tablet {
    width: calc(40% - 10px);
  }
}

.s-textimage__title {
  font-size: 1.875rem;
  font-weight: 800;
  text-transform: none;
  position: relative;
  margin: 10px 0 25px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    text-transform: uppercase;
    font-size: 3.5rem;
    left: -50px;
    bottom: 15px;
    color: $grey;
    opacity: 0.5;
    line-height: 0.9;
    z-index: -1;
    text-align: center;

    @include non-desktop {
      left: 0;
    }

    @include mobile {
      font-size: 2.5rem;
      bottom: 10px;
    }
  }

  #cpab & {
    &::before {
      content: 'COCAMIDOPROPYL BETAINE';
    }
  }

  #cdea & {
    &::before {
      content: 'COCAMIDE DEA';
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 3px;
    width: 50px;
    left: 0;
    bottom: -10px;
    background: $lighterOrange;
  }
}

.s-textimage__image {
  position: relative;
  padding: 18px 23px;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 46px);
    height: calc(100% - 36px);
  }

  &::before {
    left: 0;
    bottom: 0;
    background: $tintOrange;
    z-index: 1;
  }

  &::after {
    left: 46px;
    bottom: 36px;
    border: 1px solid $lighterOrange;
    z-index: 3;
  }

  .s-textimage.is-flipped & {
    &::before {
      left: 46px;
      bottom: 0;
    }

    &::after {
      left: 0;
      bottom: 36px;
    }
  }

  img {
    display: block;
    position: relative;
    z-index: 2;
  }
}

// SPEC
.specification {
  padding: 50px 0;

  .body--products-glycerine & {
    background: $tintOrange2 url('../images/product-glycerine-spec.jpg') no-repeat;
    background-size: 1000px;
    background-position: right top;
    min-height: 700px;

    @include non-desktop {
      background-size: auto 700px;
    }

    @include tablet {
      background-position: 20% center;
    }

    @include mobile {
      background-image: none;
    }

    @include smallMobile {
      background-position: 47% 50px;
    }
  }

  #cpab & {
    background: $titleShadow url('../images/cpab-spec-bg.jpg') no-repeat bottom;
    background-size: cover;

    @include mobile {
      background-image: none;
    }
  }

  #cdea & {
    background: #f9e2ce url('../images/cdea-spec-bg.jpg') no-repeat 124% center;
    background-size: auto 500px;

    @include bigDesktop {
      background-position: 100% center;
    }

    @include tablet {
      background-position: 33% center;
      background-size: cover;
    }

    @include mobile {
      background-image: none;
    }
  }
}

.specification__title {
  font-size: 1.625rem;
  font-weight: 800;
  text-transform: none;
  margin-top: 0;
}

.specification__info {
  .info {
    display: inline-block;
    line-height: 1;

    &:not(:last-child) {
      padding-right: 15px;
      margin-right: 15px;
      border-right: 2px solid $darkText;
    }
  }
}

.specification__cas {
  span {
    display: inline-block;
    vertical-align: top;
  }
}

.specification__content {
  width: 65%;

  @include tablet {
    width: 80%;
  }

  @include mobile {
    width: 100%;
  }

  p {
    font-weight: 500;
  }
}

.specification__table {
  @include mobile {
    p:last-of-type {
      width: 70%;
    }
  }
}

.spec-table__buttons {
  display: flex;
  border-bottom: 1px solid $lighterOrange;
  margin-bottom: 50px;
  margin-top: 16px;
}

.spec-table__button {
  margin: 0 5px;
  background: $tintOrange;
  color: $stickyText;
  transition: background 0.25s ease-in-out, color 0.25s ease-in-out, transform 0.25s ease-in-out;
  padding: 8px 10px;
  flex: 1;
  border-radius: 30px;

  @include smallMobile {
    padding: 5px;
    font-size: 0.75em;
  }

  &:hover,
  &.is-active {
    background: $lighterOrange;
    color: $black;
  }

  &.is-active {
    transform: translateY(100%);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.spec-table__table {
  border: 0;
  margin-bottom: 30px;
  border-spacing: 0;
  width: 100%;

  tr {
    &:first-child {
      td {
        border-top: 2px solid $lighterOrange;
        border-bottom-width: 2px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1em;

        .body--products-glycerine &:first-child {
          background: #e8d8c7;
          width: 100px;
          text-align: center;
          padding: 0 10px;
        }
      }
    }

    &:last-child {
      td {
        border-bottom: 2px solid $lighterOrange;
      }
    }
  }

  td {
    border: 0;
    border-bottom: 1px solid $lighterOrange;
    padding: 8px 0 8px 20px;
    font-size: 1rem;
    width: 42%;

    @include mobile {
      padding: 8px 8px 8px 12px;
    }

    @include smallMobile {
      padding: 8px;
    }

    &:last-child {
      padding: 8px 0;
    }

    &[rowspan] {
      padding: 8px;
      width: 100px;
      text-align: center;

      .body--products-glycerine & {
        background: #e8d8c7;
        border-bottom: 2px solid $lighterOrange;
        font-weight: 600;
      }

      #cpab & {
        background: #e7e1dd;
      }

      #cdea & {
        background: #efd9c6;
      }
    }
  }
}

// FEATURE
.feature {
  background: $titleShadow;
  padding: 60px 0;

  @include mobile {
    padding: 40px 0;
  }
}

.feature__title {
  text-transform: none;
  font-weight: 800;
  font-size: 1.5rem;
  margin-top: 0;
  color: $darkText;
}

.feature__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.feature__item {
  width: calc(33% - 10px);
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  height: 230px;
  overflow: hidden;
  line-height: 1.2;

  @include mobile {
    width: calc(50% - 10px);
    height: 150px;
  }

  &:hover {
    .content {
      transform: scale(1.1);

      &::before {
        opacity: 0;
      }
    }
  }

  .content {
    background-image: url('http://placehold.it/300x300');
    background-position: center center;
    background-size: cover;
    transition: transform 0.25s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    position: relative;
    min-height: auto;
    height: 100%;

    @include mobile {
      padding: 10px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.4);
      transition: opacity 0.25s ease-in-out;
    }
  }

  .text {
    color: $white;
    position: relative;
    font-size: 1.375rem;
    ;
    font-weight: 800;
    text-shadow: 0 1px 1px rgba($color: #000000, $alpha: 0.65);
    pointer-events: none;
    z-index: 2;

    @include mobile {
      font-size: 1.125rem;
    }
  }
}

.js-target-spec,
.js-target-app {
  display: none;

  &:first-child {
    display: block;
  }
}

// APPLICATION
.application {
  padding: 60px 0;
  overflow: hidden;

  @include mobile {
    padding: 40px 0;
  }

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &.is-flipped {
    .container {
      direction: rtl;

      &>* {
        direction: ltr;
      }
    }
  }
}

.application__title {
  text-transform: none;
  font-weight: 800;
  font-size: 1.5rem;
  margin-top: 0;
  color: $darkText;
}

.applications__text {
  text-align: left;
  margin-top: 0;
  margin-bottom: 15px;

  @include mobile {
    margin-bottom: 0;
  }
}

.application__text {
  text-align: center;
  position: relative;
  z-index: 2;

  @include mobile {
    margin-bottom: 25px;
  }
}

.application__buttons {
  text-align: center;
  padding: 0 15px 0 0;
  margin-right: 15px;
  border-right: 2px solid $grey;
  width: calc(100% - 200px);

  @include mobile {
    width: calc(55% - 10px);
  }
}

.application__button {
  padding: 8px;
  width: 100%;
  margin: 7px 0;
  border-radius: 30px;
  color: $stickyText;
  background: transparent;
  text-transform: uppercase;
  transition: background 0.25s ease-in-out, color 0.25s ease-in-out;
  color: $darkText;
  background: $tintOrange;

  &.is-active,
  &:hover {
    background: $lighterOrange;
    color: $darkText;
  }

  @include smallMobile {
    font-size: 0.875em;
  }
}

.application__smallimage {
  position: absolute;
  left: -100px;
  bottom: -60px;

  .body--products-glycerine & {
    left: auto;
    right: -130px;
    bottom: -70px;

    @include mobile {
      right: -50px;
      bottom: -60px;
      width: 200px;
    }
  }

  .body--products-surfactants & {
    @include mobile {
      width: 160px;
      left: -20px;
    }
  }
}

.application__buttons,
.application__examples {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 20px;
}

.application__examples {
  width: 160px;
  text-align: left;

  @include mobile {
    width: calc(45% - 10px);
  }

  .body--products-glycerine & {
    width: 180px;
    vertical-align: top;
    margin-top: 10px;

    @include mobile {
      width: calc(45% - 10px);
    }
  }
}

.applications__list {
  list-style: outside;
  margin-left: 20px;
}

.application__content {
  width: calc(43% - 10px);
  margin-bottom: 20px;

  @include tablet {
    width: calc(57% - 10px);
    margin: 20px auto;
  }

  @include mobile {
    width: 100%;
    margin: 20px auto;
  }
}

.application__image {
  position: relative;
  padding: 18px 23px;
  width: calc(57% - 10px);

  @include tablet {
    margin: 0 auto;
    width: calc(80% - 10px);
  }

  @include mobile {
    width: 100%;
    margin: 15px 0 20px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: calc(100% - 46px);
    height: calc(100% - 36px);
  }

  &::before {
    left: 46px;
    bottom: 0;
    background: $tintOrange;
    z-index: 1;
  }

  &::after {
    left: 0;
    bottom: 36px;
    border: 1px solid $lighterOrange;
    z-index: 3;
  }

  .body--products-glycerine & {
    &::before {
      left: 0;
      bottom: 0;
    }

    &::after {
      left: 46px;
      bottom: 36px;
    }
  }

  img {
    position: relative;
    z-index: 2;
  }
}

// RANGE
.range__list {
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.range__item {
  background: $tintOrange;
  padding: 55px 35px;
  width: calc(50% - 10px);
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @include non-desktop {
    padding: 40px 20px;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 3px;
    background: $lighterOrange;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    @include non-desktop {
      width: 35px;
    }
  }

  &::before {
    top: 30px;

    @include non-desktop {
      top: 22px;
    }
  }

  &::after {
    bottom: 30px;

    @include non-desktop {
      bottom: 22px;
    }
  }
}

.request {
  padding: 20px 0;
  background: $grey;
  text-align: center;
  font-weight: 600;

  p {
    margin-top: 0;
  }
}

// Certification
.certification {
  padding: 40px 0 60px;
  background: $tintOrange2;

  @include mobile {
    padding-top: 60px;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}

.certification__title {
  text-transform: none;
  font-weight: 800;
  font-size: 1.5rem;
  margin-top: 0;
  color: $darkText;

  @include tablet {
    font-size: 1.25rem;
  }
}

.certification__content {
  width: calc(30% - 20px);

  @include mobile {
    width: 100%;
  }
}

.certification__list {
  width: 67%;
  padding-left: 30px;

  @include tablet {
    padding-left: 10px;
  }

  @include mobile {
    width: 100%;
    padding: 0;
  }
}

.certification__list {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;

  @include mobile {
    margin-top: 20px;
  }
}

.certification__item {
  width: calc(25% - 15px);
  position: relative;
  margin: 5px 5px 40px;

  @include mobile {
    width: calc(50% - 15px);
    margin: 5px 5px 20px;
  }

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &:hover {
    .text {
      opacity: 1;

      &::after {
        transform: translateX(-50%) scaleY(1);
      }
    }

    .certification__shadow {
      transform: translateY(7px) scale(1.15);
    }

    .certification__image {
      transform: scale(1.15);

      img {
        opacity: 0;
      }
    }
  }

  .text {
    font-family: $newFontCondensed;
    text-transform: uppercase;
    color: $white;
    opacity: 0.7;
    font-size: 1.5rem;
    font-weight: bold;
    transition: opacity 0.25s ease-in-out;
    position: relative;
    z-index: 2;
    width: calc(100% - 20px);
    text-align: center;
    line-height: 1;
    margin-bottom: 7px;
    user-select: none;

    .smaller {
      display: block;
      font-size: 0.5em;
      font-weight: 600;
      text-transform: none;
      margin-top: 5px;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 50px;
      height: 2px;
      background: $white;
      left: 50%;
      bottom: -12px;
      transform: translateX(-50%) scale(0);
      transition: transform 0.25s ease-in-out;
    }
  }
}

.certification__shadow,
.certification__image {
  position: absolute;
  left: 0;
  width: 100%;
}

.certification__shadow {
  bottom: -40px;
  height: 40px;
  transition: transform 0.25s ease-in-out;
  transform-origin: top center;
}

.certification__image {
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.25s ease-in-out;
  transform-origin: bottom center;
  background: url('../images/certification-hover.png') no-repeat;
  background-size: cover;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.25s ease-in-out;
  }
}