.breadcrumb {
  margin: 0 0 15px;
}

.breadcrumb__list {
  display: inline-block;
  transition: opacity 0.35s ease-in-out;
  text-transform: uppercase;
  font-weight: 500;
  font-family: $secondaryFont;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 8px 5px;
  background: $darkerGrey;
  box-shadow: inset 0 0 10px #000;
  color: $grey;
  line-height: 1;
}

.breadcrumb__item {
  display: inline-block;
  position: relative;
  line-height: 1;
  padding: 0 10px;
  font-family: $secondaryFont;

  &:last-child::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: $white;
  }

  &:first-child::before {
    display: none;
  }
}

.breadcrumb__title {
  @include gradientOrange;
  font-size: 1.375em;
  margin: 0 0 0 20px;
  display: inline-block;
  text-transform: none;

  @include mobile {
    display: block;
    margin: 10px 0;
  }
}