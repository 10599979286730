.scrolling-facts {
  border-radius: $borderRadius 0 0 $borderRadius;
  box-shadow: -2px 0 4px rgba(100, 100, 100, 0.6);
  overflow: hidden;
  border: 4px solid $grey2;
  border-right: 0;

  @include mobile {
    margin-top: 20px;
    border-radius: $borderRadius;
    border-right: 4px solid $grey2;
    box-shadow: 0 0 4px rgba(100, 100, 100, 0.6);
  }
}

.scrolling-facts__title {
  background: $orange;
  color: $white;
  text-transform: none;
  font-weight: normal;
  padding: 10px 15px;
  margin: 0;
  line-height: 1.4;
}

.scrolling-facts__content {
  background: $lighterOrange;
  height: 140px;
  overflow: hidden;
  padding: 15px;
}

.scrolling-facts__list {
  animation: ticker 40s infinite linear;

  &.is-short {
    animation-duration: 20s;
  }

  .scrolling-facts__content:hover & {
    animation-play-state: paused;
  }
}

.scrolling-facts__item {
  padding-bottom: 20px;
  font-size: 11px;

  @include mobile {
    font-size: 0.75rem;
    ;
  }

  p {
    margin-bottom: 0;
  }
}

.scrolling-facts__subtitle {
  font-weight: bold;
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 5px;
  text-transform: none;
  line-height: 19px;
  font-family: $defaultFont;
}

.scrolling-facts__description {
  font-weight: bold;
  margin-top: 5px;
  color: $darkerGreen;
  line-height: 16px;
  font-family: $defaultFont;
}

@keyframes ticker {
  100% {
    transform: translateY(-100%);
  }
}